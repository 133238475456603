.heading {
  justify-content: space-around;
  min-height: 40px;
  .heading-inner-row {
    align-items: center;
  }

  .heading-name {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #2b323b;
  }
  .ibr-app-type-span {
    margin-left: 24px;
    padding: 3px 8px 5px 8px;
    border-radius: 4px;
    background: #e6eefa;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #2f74d3;
  }

  .personal-card-btn-col {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .app-id-indicator {
    font-style: normal;
    background: #f2f9fe;
    border-radius: 4px;
    padding: 3px 8px 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin-left: 10px;
  }
  .copy-display-option {
    color: #2f74d3;
    font-weight: 700;
    margin-left: 8px;
    img {
      height: 24px;
      width: 24px;
    }
  }

  .app-id {
    color: #0049a4;
  }

  .collapse-btn {
    justify-content: space-between;
    align-items: center;
  }
}

.id-rows {
  margin-left: 6px;
  margin-bottom: 24px;
  .app-id-indicator {
    font-style: normal;
    color: #000000;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
  .copy-display-option {
    color: #2f74d3;
    font-weight: 700;
    margin-left: 12px;
    img {
      height: 30px;
      width: 30px;
    }
  }

  .app-id {
    color: #0049a4;
    font-weight: 600 !important;
  }
  .app-id-link {
    cursor: pointer;
    text-decoration: underline;
    color: #0049a4;
    font-weight: 700 !important;
  }
}

.status-row {
  height: 30px;
  justify-content: space-between;
  // padding-right: 2%;
  margin-top: 4px;
  margin-bottom: 8px;
  align-items: center;
  .left-mg {
    margin-left: 8px;
  }
  .ibr-status {
    font-size: 14px !important;
  }
  .start-date-col {
    .ibr-start-date {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #656c7c;
    }
  }
}

.personal-detail-divider {
  margin-top: 1%;
  color: #edf0f5;
}

.school-details-data {
  justify-content: space-between;
  align-items: center;

  .inner-row {
    justify-content: space-between;
  }

  .school-details-leftside-data {
    // min-height: 50px;
    padding: 12px 0 2px 0;
    border-bottom: 1px solid #edf0f5;
  }

  .school-details-rightside-data {
    // min-height: 50px;
    padding: 12px 0 2px 0;
    border-bottom: 1px solid #edf0f5;
  }

  .right-span {
    display: flex;
    justify-content: flex-end;
  }

  .school-details-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #656c7c;
  }

  .empty-label {
    margin-top: 20px !important;
  }
  .school-details-edit-date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #656c7c;
  }
  .school-details-value {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  .school-details-value-except {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    .inner-span-value {
      font-size: 13px !important;
      font-weight: 400 !important;
    }
  }
  .special-case {
    padding-left: 20px;
  }
}

@media screen and (max-width: 992px) {
  .school-details-data {
    margin: 0px 12px;
  }
}
.edit-email-modal {
  .ant-modal-body {
    padding: 24px 48px;
  }
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .edit-email-heading {
    padding: 16px;
    text-align: center;
    font-family: Open Sans;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
  }
  .edit-email-message {
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0px 2px 4px;
  }
  .w-178 {
    width: 178px;
  }
}
