.cosinger-release-container {
    min-height: 100vh;

    .cosigner-release-header {
        padding: 30px;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);

        h2 {
            font-weight: 700;
            font-size: 30px;
            font-style: normal;
            line-height: 41px;
        }
    }

    .cosigner-release-body {
        padding: 20px;

        .search-input {
            padding-bottom: 20px;

            .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
                border-top-left-radius: 8px !important;
                border-bottom-left-radius: 8px !important;
            }

            .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
                border-radius: 0 8px 8px 0 !important;
            }
        }

        .page-details {
            display: flex;
            justify-content: end;
            gap: 12px;

            span {
                font-size: 16px;
            }
        }

        .cosigner-release-table {
            th {
                background: #f7f8fb !important;
                font-weight: 700 !important;
                font-size: 12px !important;
                line-height: 16px !important;
                color: #656c7c !important;
            }

            td {
                font-weight: 400 !important;
                font-size: 14px !important;
                line-height: 20px !important;
                color: #2b323b !important;
            }

            .student-name {
                color: #2f74d3 !important;
                cursor: pointer;
            }

            .ibr-id-badge {
                font-weight: 700;
                font-size: 12px;
                line-height: 24px;
                color: #0049a4;
                padding: 3px 6px;
                background: #f2f9fe;
                border-radius: 4px;
            }
        }

        .antd-pagination-student {
            margin: 30px 0 !important;
        }
    }
}