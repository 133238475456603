//antd custom tab
.ant-custom-tab {
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  //   .ant-tabs-tab
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
  }
  .ant-tabs-tab-btn {
    color: #656c7c;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
    background: #2f74d3;
  }
  .ant-tabs-extra-content {
    margin-bottom: 20px;
  }
}

.forbearence-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
//forebearence header

.forebearence-header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 20px 0px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;

  .heading-col {
    display: flex;
    align-items: center;

    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: #2b323b;
    }
  }
  .search-input {
    .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      border-color: #656c7c;
    }

    .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
      border-radius: 0 8px 8px 0 !important;
      border-color: #656c7c;
    }
  }
}

.request-card {
  border-radius: 8px;
  .ant-card-body {
    padding: 16px 24px;
  }
  .name {
    color: #2f74d3;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .detail {
    color: #656c7c;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .req-title {
    color: #656c7c;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
    text-align: right;
  }
  .req-value {
    color: #111c2a;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    text-align: right;
  }
}

.forebearence-content {
  background: #f2f2f2;
  padding: 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  overflow-y: auto;
  .content-title {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-transform: capitalize;
  }
}
.type-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edf0f5;
  padding: 0 8px 16px 8px;

  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
  }
}
.type-row-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #656c7c;
}
.doc-row {
  gap: 24px;
  .doc-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 14px 0px #00000026;
    padding: 12px 16px;
    border-radius: 8px;
    .doc-title {
      margin-left: 8px;
      color: #2b323b;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    .doc-btns {
      color: #0049a4;
    }
  }
}

.forbearance-detail-container {
  background-color: #f2f2f2;
  padding: 16px 20px;
  width: 100%;
  .forbearence-id-row {
    margin-bottom: 16px;
    .id-indicator {
      font-style: normal;
      background: #f2f9fe;
      border-radius: 4px;
      padding: 3px 8px 5px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
    .copy-display-option {
      color: #2f74d3;
      font-weight: 700;
      margin-left: 4px;
      cursor: pointer;
      img {
        height: 24px;
        width: 24px;
      }
    }
  }
  .forbearance-detail-card {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 2px 13px 0px #00000027;
    margin-bottom: 24px;
    .forbearance-heading-row {
      display: flex;
      align-items: center;
      gap: 8px;
      // margin-bottom: 16px;
    }
    .forbearance-back-icon {
      color: #c3c6cd;
      font-size: 18px;
      cursor: pointer;
      margin-bottom: 16px;
    }
    .forbearence-action-heading {
      color: #2b323b;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }

  .prev-forbearance-detail-container {
    // margin-top: 24px;
    // padding: 16px;
    flex-direction: column;
    width: 100%;
    .length-heading {
      color: #2b323b;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
    }

    .ant-forbearance-collapse {
      background-color: transparent;
      align-items: flex-start;
      .ant-collapse-item {
        border-bottom: none;
        background-color: #ffffff;
        border-radius: 8px;
        margin-bottom: 24px;
      }
    }
  }
}

.forbearance-form {
  .border-radius {
    border-radius: 8px;
  }
}
