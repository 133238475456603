.approve-card {
  margin-left: 8%;
  margin-right: 2%;
  .blue-text {
    color: #2f74d3 !important;
    padding: 4px 10px !important;
  }

  .red-text {
    color: #f22d55 !important;
    padding: 4px 10px !important;
  }

  .approve-crd-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #2b323b;
  }

  .approve-alert-div {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    margin-top: 12px;
    height: 54px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 2px;
  }

  .card-content-row {
    align-items: center;
    background: #f2f9fe;
    margin-top: 12px;
    height: 54px;

    .card-content-col1 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #2b323b;
      .card-content-list {
        margin: 0px !important;
      }
    }
    .card-content-col2 {
      display: flex;
      justify-content: flex-end;
      padding-right: 4%;
      span.modal-link {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #2f74d3;
        cursor: pointer;
      }
      span.success-text {
        color: #52c41a;
      }
      span.denied-text {
        color: #f5222d;
      }
    }
  }
  .approve-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
    border-radius: 2px;
  }
  .approve-denied {
    background-color: #fff1f0;
    border: 1px solid #ffccc7;
    border-radius: 2px;
  }
}

.deactivate-modal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .session-text {
    text-align: center;
    padding: 0.8rem 0.5rem 0.3rem;
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }
    .ant-checkbox-wrapper {
      margin-top: 10px;
      text-align: left;
    }
    .ant-checkbox + span {
      padding-right: 0;
    }
    .credit-pass-warning {
      color: #faad14;
      font-size: 42px;
      margin-bottom: 8px;
      font-weight: 700;
    }
  }
}

.disbursment-text {
  text-align: center;
  padding: 0.8rem 0.5rem 0.3rem;
  margin-bottom: 24px;
  font-weight: 700;
  color: #000000;
}
