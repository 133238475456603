.offer-heading-row {
  align-items: center;
  // min-height: 32px;
  margin: 18px 0px 8px;
  padding: 0px 16px;

  .offer-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #2b323b;
    margin: 0 !important;
  }

  .flags-head {
    color: #f22d55;
    font-weight: 700;
  }

  .onelogos-blue-container {
    padding: 6px 12px 6px;
    margin: 0;
    border-radius: 4px;
    background-color: #0049a4;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
}

.offer-data-main-row {
  justify-content: space-between;
  align-items: center;

  .offer-data-col {
    min-width: 100%;
    // min-height: 50px;
    padding: 12px 16px 6px 16px !important;
    border-bottom: 1px solid #edf0f5;

    .offer-data-header-row {
      border-bottom: 1px solid #edf0f5;
      margin: 4px 4px 8px 4px;
      padding-bottom: 8px;

      .offer-data-header {
        font-weight: 700 !important;
        margin-bottom: 0 !important;
      }
    }

    .offer-data-inner-row {
      .Transcript-col-span-id {
        font-weight: 700;

        span {
          word-break: break-all;
        }
      }

      .offer-data-label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #656c7c;
        margin: 0px !important;
      }

      .offer-data-date {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #656c7c;
        margin: 0px !important;
      }

      .blue-container-div {
        margin-right: 10px;
      }

      .right-span {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .offer-data-value {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          margin: 0px !important;
        }
      }

      .doc-col {
        display: flex;
        align-items: center;
        gap: 6px;
      }
    }

    .inner-row {
      justify-content: space-between;

      .offer-data-label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #656c7c;
        margin: 0px !important;
      }

      .offer-data-value {
        margin: 0px !important;
      }

      .right-span {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .offer-data-status {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #656c7c;
          margin: 0px !important;
        }
      }
    }
  }

  .offer-doc-col {
    padding: 8px 15px !important;
    width: 100%;

    .offer-data-inner-row {
      .doc-col {
        display: flex;
        align-items: center;
        gap: 6px;

        .orange-mark {
          color: #ff845e !important;
        }

        .offer-data-label {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #656c7c;
          margin: 0px !important;
        }
      }

      .offer-data-date {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #656c7c;
        margin: 0px !important;
      }

      .right-span {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .offer-data-inner-row2 {
      justify-content: space-between;

      .doc-col {
        display: flex;
        align-items: center;
        gap: 6px;

        .offer-data-label {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #656c7c;
          margin: 0px !important;
        }
      }

      .offer-data-date {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #656c7c;
        margin: 0px !important;
      }

      .right-span {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .pull-expand-col1 {
    background: #fef2f4;

    .knockout-rules-div {
      min-width: 100%;

      .knockout-heading {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #2b323b;
        padding: 16px 16px 8px;
      }

      .rules-row {
        .knockout-rules {
          padding-left: 16px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;

          .knock-rule {
            margin-bottom: 8px !important;

            .green-tick {
              margin-right: 1%;
              color: #5ccd83 !important;
            }

            .red-mark {
              margin-right: 1%;
              color: #f22d55 !important;
            }
          }
        }
      }
    }
  }

  .pull-expand-col-2 {
    background: #fff9dc;
  }
  .red-flags-div {
    padding-left: 16px;

    .flags-heading {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #2b323b;
      padding: 16px 0px 8px;
    }

    .flags-row {
      .red-flags {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #2b323b;

        .red-mark {
          margin-right: 2%;
          color: #f22d55 !important;
        }

        .green-tick {
          margin-right: 2%;
          color: #5ccd83 !important;
        }
      }
    }
  }

  .pull-expand-col-3 {
    background: #f7f8fb;

    .flags-div {
      padding-left: 16px;
      padding-right: 16px;

      .flags-heading {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #f22d55;
        padding: 16px 0px 8px;
      }
    }
  }

  .tick-mark {
    color: #f22d55 !important;
  }

  .pull-expand-col-4 {
    background: #e4faec;

    .disbursement-div {
      padding: 0 15px;
      align-items: baseline;

      .disbursement-heading {
        margin-left: 5px;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #25a050;
        padding: 10px 0px 4px;
      }

      .disbursement-date {
        margin-left: 45px;
        margin-top: 10px;
        color: #656c7c;
        font-weight: 700;
      }

      .disbursement-icon {
        margin-top: 12px;
      }

      .green-tick-mark {
        color: #5ccd83 !important;
      }
    }
  }
}

.histoy-modal {
  .ant-modal-content {
    border-radius: 12px !important;

    .ant-modal-close-x {
      font-size: 24px !important;
    }
  }

  .ant-modal-title {
    color: #2b323b;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }

  .ant-modal-header {
    border-radius: 12px;
    border-bottom: none !important;
  }

  thead {
    tr {
      th {
        background-color: #f7f8fb !important;
      }
    }

    ::before {
      width: 0 !important;
    }

    .ant-table-cell {
      color: #2b323b;
      font-size: 12px;
      font-weight: 700;
      line-height: normal;
    }
  }

  tbody {
    .ant-table-cell {
      color: #2b323b;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .ant-modal-body {
    padding: 12px 0 24px 0;

    .ant-table-container table > thead > tr:first-child th:first-child {
      padding-left: 24px !important;
    }

    .ant-table-row-level-0 td:first-child {
      padding-left: 24px !important;
    }
  }
}

.certification-histoy {
  .ant-pagination {
    margin: 16px !important;
  }
}

.document-history {
  .ant-modal-header {
    border-radius: 12px 12px 0px 0px !important;
    border-bottom: 1px solid #edf0f5 !important;
  }

  .document-history-container {
    padding: 0 24px;

    .document-header {
      background-color: #f7f8fb;
      padding: 25px;
      margin-bottom: 20px;

      h4 {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }
    }

    .documents-date {
      padding-left: 25px;
      font-size: 14px;
      line-height: 20px;
    }

    .view-download-button {
      font-size: 14px;
      line-height: 20px;
      color: #0049a4;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.session-main-col {
  background: #fff9dc;

  .session-head-row {
    min-width: 100%;
    padding: 12px 16px 6px 16px !important;
    border-bottom: 1px solid #edf0f5;

    .red-mark {
      margin-right: 1%;
      color: #f22d55 !important;
    }

    .session-rejected-main-head {
      color: #f22d55;
      font-weight: 700;
      font-size: 14px !important;
    }

    .session-rejected-btn-cols {
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #2f74d3;
      cursor: pointer;
    }
  }

  .session-head-row2 {
    min-width: 100%;
    padding: 12px 16px 6px 16px !important;

    .red-mark {
      margin-right: 1%;
      color: #f22d55 !important;
    }

    .session-rejected-main-head {
      color: #f22d55;
      font-weight: 700;
      font-size: 14px !important;
    }

    .session-rejected-btn-cols {
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #2f74d3;
      cursor: pointer;
    }
  }

  .s-r-detail-main-row {
    .s-r-detail-main-col {
      padding: 12px 16px 6px 3% !important;
      border-bottom: 1px solid #edf0f5;

      .s-r-detail-head {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #656c7c;
      }
    }
  }
}

.admin-action-reason {
  background-color: #f7f8fb;
  width: 100%;
  padding: 15px 16px 10px 16px;

  .declined {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #f22d55;
  }

  .approved {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #25a050;
  }

  .action-reason {
    margin-top: 10px;
  }
}

.loan-selection-container {
  margin: 16px;
  width: 100%;
  .offer-doc-col-new {
    padding: 8px 0px !important;
  }
  .loan-heading {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #2b323b;
    margin-bottom: 8px;
  }
  .loan-selection-card {
    background-color: #ffffff;
    margin-top: 8px;
    padding: 16px;
    border-radius: 8px;
    .loan-name-row {
      background-color: #f5f9fe;
      padding: 16px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .selected-text {
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 16px;
        line-height: 38px;
        letter-spacing: 0px;
        color: #2f74d3;
      }
      .loan-name {
        font-family: "Open Sans";
        font-weight: 700;
        font-size: 16px;
        line-height: 38px;
        letter-spacing: 0px;
        text-align: center;
      }
    }
    .selected {
      background-color: #fff;
      border: 3px solid #2f74d3;
      justify-content: space-between;
    }
    .new-crietaira {
      padding-left: 0;
    }
  }
}
